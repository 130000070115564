<template>
  <div class="login-bg">
    <div class="left_title">博今智推后链路服务网站</div>
    <div class="form-box">
      <div class="title">管理后台</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="用户名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">登录</el-button>
          <el-button>重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom">
      <p class="public-security touch" @click="openBeian">
        <el-image :src="public_icon" class="public-icon" />
        粤公网安备 44010602009639号
      </p>
      <p>广东博今信息技术集团有限公司</p>
      <div class="mt10">
        <span class="touch1">手机版</span> | <span class="touch1">管理登录</span> |
        <span class="touch" @click="openICP">粤ICP备20017358号-12</span>
      </div>
      <div class="record-no mt10">
        <div v-for="(item, i) in recordNos" :key="`${item}''${i}`">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginIndex',
  components: {},
  data() {
    return {
      ruleForm: {
        name: '',
        password: ''
      },
      rules: {
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      recordNos: ['0', '0', '0', '1', '6', '2', '5', '8'],
      public_icon: require('@/assets/images/login/public_security_icon.png')
    }
  },
  mounted() {},
  methods: {
    openBeian() {
      this.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009639')
    },
    openICP() {
      this.open('http://beian.miit.gov.cn')
    },
    open(url) {
      window.open(url, '_bank')
    }
  }
}
</script>

<style lang="scss" scoped>
.login-bg {
  width: 100%;
  height: 100%;
  background: url('@/assets/images/login/bg.jpg') no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .left_title {
    position: absolute;
    left: 2%;
    top: 3%;
    font-size: 32px;
  }
  .title {
    font-size: 32px;
    color: rgb(40 40 40);
    font-weight: 600;
    margin: 50px 0;
    text-align: center;
  }
  .form-box {
    width: 500px;
    background: #fff;
    opacity: 0.9;
    :deep .el-form {
      width: 90%;
    }
  }
  .bottom {
    font-size: 12px;
    text-align: center;
  }
  .record-no {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    div {
      background-color: #495158;
      margin-right: 5px;
      width: 14px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      color: #91969a;
      font-size: 16px;
    }
  }
  .mt10 {
    margin-top: 10px;
  }
  .public-security {
    display: flex;
    align-items: center;
    .public-icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
  .touch:hover {
    color: #fff;
    cursor: pointer;
  }
}
</style>
